import React, { useEffect } from 'react';

const JivoChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://code.jivosite.com/widget/sS59tDIsV0';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // The chat widget is added to the DOM, so no need to render anything
};

export default JivoChat;